export default class FWarehouse {
  constructor(
      id,
      kode1,
      kode2,
      description,
      avatarImage,
      fdivisionBean,
      productHppSaved,
      numberPriority,
      gudangUtama,
      address1,
      district1,
      city1,
      state1,
      phone,
      countryCode,
      zipCode,
      email,
      senderName,
      statusActive,
      gudangPo,
      gudangSo,
      gudangTransfer,
      gudangRetail,
      gudangPusatCompany,
      gudangTransitDiv,
      tipeWarehouse,
      lat,
      lon,
      sourceId,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.avatarImage = avatarImage;
    this.fdivisionBean = fdivisionBean;
    this.productHppSaved = productHppSaved;
    this.numberPriority = numberPriority;
    this.gudangUtama = gudangUtama;
    this.address1 = address1;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;
    this.zipCode = zipCode;
    this.email = email;
    this.senderName = senderName;
    this.statusActive = statusActive;
    this.gudangPo = gudangPo;
    this.gudangSo = gudangSo;
    this.gudangTransfer = gudangTransfer;
    this.gudangRetail = gudangRetail;
    this.gudangPusatCompany = gudangPusatCompany;
    this.gudangTransitDiv = gudangTransitDiv;
    this.tipeWarehouse = tipeWarehouse;
    this.lat = lat;
    this.lon = lon;
    this.sourceId = sourceId;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }
}